exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-study-fast-js": () => import("./../../../src/pages/case-study-fast.js" /* webpackChunkName: "component---src-pages-case-study-fast-js" */),
  "component---src-pages-case-study-money-super-market-js": () => import("./../../../src/pages/case-study-money-super-market.js" /* webpackChunkName: "component---src-pages-case-study-money-super-market-js" */),
  "component---src-pages-case-study-walkies-js": () => import("./../../../src/pages/case-study-walkies.js" /* webpackChunkName: "component---src-pages-case-study-walkies-js" */),
  "component---src-pages-case-study-well-pharmacy-js": () => import("./../../../src/pages/case-study-well-pharmacy.js" /* webpackChunkName: "component---src-pages-case-study-well-pharmacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

